

/*Map Styles changes*/
.H_l_bottom {
  top: 16px;
}

.H_l_horizontal .H_scalebar {
  display: none
}

.H_l_bottom.H_l_right {
  .H_l_anchor.H_l_horizontal {
    .H_ctl.H_el {
      margin-top: 0;

      .H_btn.H_el {
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 8px;
        width: 25px;
        height: 25px;

        svg.H_icon {
          fill: #91AAB9 !important;
          width: 18px;
        }
      }

      .H_overlay.H_open {
        top: 0px;
        bottom: auto !important;

        &:after {
          border-color: transparent transparent transparent #fff;
        }

        &:after, &:before {
          top: 10px;
          bottom: auto;
        }

        .H_rdo_title {
          background: #fff;
        }

        .H_el {
          .H_btn {
            background: none;
            width: 184px;
            border-radius: 0;
            margin-bottom: 0;
            box-shadow: none;
          }
        }
      }
    }
  }

  .H_l_anchor.H_l_vertical {
    .H_ctl.H_el.H_zoom.H_grp {
      background: none;
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0 !important;

      .H_btn {
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 8px;
        width: 25px;
        height: 25px;

        svg.H_icon {
          fill: #91AAB9 !important;
        }
      }
    }
  }
}

.map_filters {
  .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 19px;
    height: 19px;
    color: #495057;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #53a4f2 !important;
    background: #53a4f2 !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #53a4f2 !important;
    background: #53a4f2 !important;
    color: #ffffff;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 12px;
  }
}

.mapBlocks canvas {
  width: 100% !important;
}
/* ./Map Styles changes*/

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: none !important;
  color: #000 !important;
}

.ptable-css p-paginator .p-paginator .p-dropdown {
  margin-left: 0;
  margin-right: 0;
  padding: 5px;
  height: auto;
  color: #6D7B83;
  font-size: 12px;
  display: none !important;
}

.ptable-css p-paginator .p-paginator .p-paginator-current {
  margin: 0;
  padding: 0;
  display: none;
}

.p-paginator .p-paginator-first {
  display: none !important;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.p-paginator .p-paginator-last {
  display: none !important;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}


/* Custom Scrollbar using CSS */
.p-scrollpanel {
  .p-scrollpanel-content {
    padding: 0;
  }

  .p-scrollpanel-bar {
    background: #91AAB9 !important;
  }

  .p-scrollpanel-bar-y {
    width: 6px;
  }

  .p-scrollpanel-bar-x {
    height: 6px;
  }
}

.ptable-scrollable .p-datatable-scrollable .p-datatable-wrapper {
  /* scrollbar width */
  &::-webkit-scrollbar {
    cursor: pointer !important;
    width: 8px;
    height: 8px;
  }
  /* scrollbar track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #EDEDED;
  }
  /* scrollbar handle */
  &::-webkit-scrollbar-thumb {
    &:hover {
      background-color: #19A3F1;
    }

    border-radius: 1rem;
    background-color: #91AAB9;
  }
}

.custom-scrollbar, .p-dialog-content, .p-multiselect-items-wrapper {
  /* scrollbar width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* scrollbar track */
  &::-webkit-scrollbar-track {
    background: #EDEDED;
  }
  /* scrollbar handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #91AAB9;
  }
}

.fillert-box-lists {
  ul.nav {
    @extend .custom-scrollbar;
    overflow: auto;
    height: 100%;
  }
}

.fillet-boxs-css {
  .p-checkbox {
    width: 12px;
    height: 12px;
  }

  .p-checkbox .p-checkbox-box {
    border: #C9E3F2 solid 1px;
    width: 12px;
    height: 12px;
    border-radius: 1px;
    align-items: center;
    display: flex;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 6px;
    padding-top: 2px;
  }

  .p-checkbox .p-checkbox-box.p-highlight, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: $defaultColor;
    border-color: $defaultColor
  }

  .p-multiselect {
    border-radius: 2px;
    min-width: 100%;
    width: 100%;
    background: #FBFDFF;
    border: #C9E3F2 solid 0.5px;
    font-size: 12px;
    line-height: 14px;
    color: #A4CEE5;

    .p-multiselect-trigger {
      background: transparent;
      color: #6c757d;
      width: 2rem !important;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .p-multiselect-trigger-icon {
      color: #a4cee5;
    }
  }

  .p-multiselect-panel {

    .p-multiselect-items {
      .p-multiselect-item {
        padding: 0 12px;
        color: #000;
        font-size: 12px;
        line-height: 25px;
      }

      .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: #000;
        background: #fff;
      }

      &:not(.p-multiselect-virtualscroll) {
        padding: 5px 0;
      }
    }

    .p-multiselect-header {
      padding: 7px;
      background: #fafcfe;

      .p-multiselect-filter-container .p-inputtext {
        padding: 0;
        border-radius: 2px;
      }

      .p-multiselect-close {
        width: 1rem;
        height: 1rem;

        .pi {
          font-size: 12px;
        }
      }

      .p-multiselect-filter-container .p-multiselect-filter-icon {
        font-size: 12px;
        right: 7px;
        margin-top: -6px;
      }
    }
  }

  .p-dropdown {
    display: flex;
    @extend .input-bg-border;
  }

  .p-dropdown .p-dropdown-label.p-placeholder, .p-dropdown .p-dropdown-trigger .pi {
    font-size: 12px;
  }

  .p-dropdown .p-dropdown-trigger .pi {
    color: #A4CEE5
  }

  .p-dropdown .p-dropdown-clear-icon {
    right: 25px;
    font-size: 10px;
    margin-top: -6px;
  }

  .p-dropdown .p-dropdown-trigger {
    width: 25px;
  }

  .p-dropdown .p-dropdown-label.p-inputtext.p-placeholder {
    padding: 7px 10px;
    color: #19A3F1 !important;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #19A3F1 !important;
    right: 1.5rem;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    font-size: 12px;
    color: #19A3F1 !important;
    padding: 7px 10px;
    padding-right: 12px;
  }

  .p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 5px 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0 12px;
    color: #000;
    font-size: 12px;
    line-height: 25px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: #EEF2FF
  }

  .p-dropdown:not(.p-disabled).p-focus {
    background: none !important;
    outline: none !important;
    /* border: none;*/
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #DCF2FF;
    color: $defaultColor;
    border: $defaultColor solid 0.3px;
  }

  .p-multiselect:not(.p-disabled):hover, .p-dropdown:not(.p-disabled):hover {
    border-color: $defaultColor !important;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: $defaultColor !important;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 4px;
  }

  .p-multiselect .p-multiselect-label {
    height: 38px;
    color: #A4CEE5
  }
}

.fillet-boxs-css1 {


  .p-dropdown:not(.p-disabled).p-focus {
    .p-dropdown-label {
      color: #19A3F1 !important;
    }

    .p-dropdown-trigger {
      color: #19A3F1 !important;
    }

    background: none !important;
    outline: none !important;
    border: none;
  }

  .p-dropdown {
    display: flex;
    border: none;
    width: 150px;
  }

  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    overflow: visible;
    font-size: 12px !important;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #19A3F1 !important;
    right: 3rem;
  }

  .p-dropdown .p-dropdown-label.p-inputtext.p-placeholder {
    padding: 7px 10px;
    color: #000;
    border: none;
    font-size: 12px !important;
  }

  .p-dropdown .p-dropdown-label.p-placeholder .p-dropdown .p-dropdown-trigger.pi {
    font-size: 12px;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    font-size: 12px !important;
    padding: 7px 10px;
    padding-right: 12px;
  }

  .p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 5px 0;
    font-size: 12 px !important
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0 12px;
    color: #000;
    font-size: 12px !important;
    line-height: 25px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    font-size: 12px !important;
    background: #63C3F9 !important;
    color: white !important
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    font-size: 12px !important;
    background: #63C3F9 !important;
    color: white !important
  }
}

.range-calendar2 {
  p-calendar {
    .p-inputtext {
      @extend .input-bg-border;
      padding: 6px 10px;
      font-size: 12px;
    }

    .p-button, .p-button:hover {
      @extend .input-bg-border;
      width: auto;
      padding: 0px 8px;

      .pi {
        color: #C9E3F2;
        font-size: 16px;
      }
    }

    .p-inputtext:enabled:hover, .p-button:hover {
      border-color: $defaultColor;
    }
  }

  .p-calendar .p-datepicker {
    width: 225px;
    margin-top: 10px;
    border: none !important;

    table {
      th {
        color: #CACACA;
        font-weight: normal;
        text-align: center;
        font-size: 10px;
        padding: 0;

        span {
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
      }

      td {
        color: #6D7B83;
        font-weight: 400;
        text-align: center;
        font-size: 12px;
        padding: 0;

        span {
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
      }
    }

    table td.p-datepicker-today > span {
      background: $defaultColor;
      color: #fff;
    }

    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      @extend .input-bg-border;
      color: #495057
    }

    table td > span.p-highlight {
      color: #fff;
      background: #9ED5F4;
      //border-radius:0px; &.startDate{border-radius:15px 0 0 15px;}
    }

    .p-datepicker-header {
      .p-datepicker-prev, .p-datepicker-next {
        color: $defaultColor;
        border: $defaultColor solid 2px;
        border-radius: 5px;
        width: 20px;
        height: 20px;

        .pi {
          font-size: 8px;
        }

        &:hover {
          color: $defaultColor;
          border-color: $defaultColor;
          background: #D6EDFA;
        }
      }

      .p-datepicker-title {
        button {
          color: #6D7B83;
          margin: 0;
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }
  }
}

.range-calendar {
  .p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #495057;
    /* border: 1px solid #ced4da !impotant; */
    border: none !important;
    border-radius: 6px;
  }


  p-calendar {
    ::placeholder {
      color: #19A3F1 !important;
      opacity: 1; /* Firefox */
    }

    .p-inputtext {

      @extend .input-bg-border;
      padding: 6px 10px;
      font-size: 12px;
      color: #19A3F1 !important;
    }

    .p-button, .p-button:hover {
      @extend .input-bg-border;
      width: auto;
      padding: 0px 8px;

      .pi {
        color: #C9E3F2;
        font-size: 16px;
      }
    }

    .p-inputtext:enabled:hover, .p-button:hover {
      border-color: $defaultColor;
    }
  }

  .p-calendar .p-datepicker {
    width: 300px;
    border: none !important;

    table {
      th {
        color: #CACACA;
        font-weight: normal;
        text-align: center;
        font-size: 10px;
        padding: 0;

        span {
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
      }

      td {
        color: #6D7B83;
        font-weight: 400;
        text-align: center;
        font-size: 12px;
        padding: 0;

        span {
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
      }
    }

    table td.p-datepicker-today > span {
      background: $defaultColor;
      color: #fff;
    }

    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      @extend .input-bg-border;
      color: #495057
    }

    table td > span.p-highlight {
      color: #fff;
      background: #9ED5F4;
      //border-radius:0px; &.startDate{border-radius:15px 0 0 15px;}
    }

    .p-datepicker-header {
      .p-datepicker-prev, .p-datepicker-next {
        color: $defaultColor;
        border: $defaultColor solid 2px;
        border-radius: 5px;
        width: 20px;
        height: 20px;

        .pi {
          font-size: 8px;
        }

        &:hover {
          color: $defaultColor;
          border-color: $defaultColor;
          background: #D6EDFA;
        }
      }

      .p-datepicker-title {
        button {
          color: #6D7B83;
          margin: 0;
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }
  }
}

.dialog-overflow-visible {
  .p-dialog-content {
    overflow-y: visible;
  }
}

.ptable-css {
  thead.p-datatable-thead th {
    font-size: 12px;
    line-height: 14px;
    color: #91AAB9;
    padding: 12px;
    border-color: #DDE5EA !important;
  }

  tbody.p-element.p-datatable-tbody tr.p-selectable-row td {
    font-weight: 500;
    color: #000;
    font-size: 12px;
    line-height: 14px;
    padding: 12px;
    border: none;
  }

  .p-datatable .p-sortable-column.p-highlight:hover {
    background: #fff !important;
    box-shadow: inset 0 0 0 0px #fff !important;
    color: #19a3f1 !important;
  }

  .p-datatable .p-sortable-column.p-highlight {
    box-shadow: inset 0 0 0 0px #fff !important;
    background: #fff !important;
    color: #19a3f1 !important;
  }

  p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0px #fff !important;
    border: none !important;
    outline: 0 none !important;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    color: $defaultColor;
    background: #fff;

    td {
      color: $defaultColor;
    }
  }

  p-paginator {
    .p-paginator {
      justify-content: end;
      border-width: 0;

      .p-paginator-current, button.p-link {
        min-width: auto;
        height: auto;
        color: #6D7B83;
        font-size: 12px;
      }

      .p-paginator-current {
        margin: 0;
        padding: 0;
      }

      button.p-link {
        padding: 0 8px;
        border-radius: 0;
        color: #6D7B83;
        font-size: 12px;
      }

      .p-dropdown {
        margin-left: 0;
        margin-right: 0;
        padding: 5px;
        height: auto;
        color: #6D7B83;
        font-size: 12px;

        .p-dropdown-label {
          padding: 0;
        }

        .p-dropdown-trigger {
          width: auto;
          padding-left: 3px;
        }

        .pi, .p-inputtext {
          color: #6D7B83;
          font-size: 12px;
        }
      }
    }
  }

  .p-datatable .p-datatable-header {
    background: #ffffff;
    border-width: 0;
    padding: 12px;
  }
}

.p-inputtext:enabled:hover {
  border-color: $defaultColor;
}

.p-inputgroup:enabled label {
  color: red
}
