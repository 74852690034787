/* You can add global styles to this file, and also import other style files */

$defaultColor: #19A3F1;
$colorTxt: #A4CEE6;

.input-bg-border{border-color: #C9E3F2;background:#FBFDFF ;}
.inpu-shadows{box-shadow: 0px 4px 20px rgb(184 219 239 / 71%);}

@import "~src/assets/files/styles/ngprime-cs.scss";

/*style*/
html, body {height: 100%; overflow: hidden }
body { margin: 0;font-family: 'Roboto', sans-serif; }
::placeholder,::-ms-input-placeholder,::-ms-input-placeholder {color: $colorTxt;}
.z-index-0{z-index:0;}
.z-index-1{z-index:1;}
.z-index-11{z-index:11;}
::ng-deep .gm-style div {
  border: none !important;
}
* {
    .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #53a4f2 !important;
    background: #53a4f2 !important;
    width:18px !important;
    height:18px !important;

}
    .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 18px !important;
    height: 18px !important;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
    .p-dialog{
        .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0px 15px 10px 15px !important;
}
  .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding: 5px 15px !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
               }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none !important;
    outline-offset: 0;
    box-shadow: 0 0 0 0 white !important;
    /*border-color: red !important;*/
    color:#A4CEE6 !important;
  }
}
.gm-ui-hover-effect {
  span {
    display: none !important;
  }

  &:after {
    content: 'x' !important;
  }

  color: red !important;
  width: 20px !important;
  margin: 10px !important;
  text-align: center !important;
  line-height: 0px !important;
  height: 20px !important;
  border-radius: 50% !important;
  border: 1px solid red !important;
}
.fw-bold{
    font-weight:bold !important;
}
.cursor-pointer{cursor:pointer;}
.fs-10{font-size:10px;}
.fs-12{font-size:12px;}
.fs-14{font-size:14px;}
.fs-16{font-size:16px;}
.fs-18{font-size:18px;}
.fs-20{font-size:20px;}
.lh-10{line-height: 10px;}
.lh-12{line-height: 12px;}
.lh-14{line-height: 14px;}
.lh-15{line-height: 15px;}
.lh-23{line-height: 23px;}

.txt-default-color{color:$defaultColor}
.txt-gry{color: #91AAB9;}
.txt-dgry{color:#6D7B83}
.txt-black{color: #000;}
.green-txt {color:#0CCD70;}
.red-txt{color: #FF0000;}
.txt-light-color{color: #A4CEE6;}
.all-bg-o-45{background: rgba(37, 231, 231, 0.45);}
.online-bg-o-45{background: rgba(31, 250, 66, 0.45);}
.offline-bg-o-45{background: rgba(255, 0, 0, 0.45);}
.stop-bg-o-45{background: rgba(255, 138, 0, 0.45);}
.ldle-bg-o-45{background: rgba(0, 87, 255, 0.45);}
.signal-lost-bg-o-45{background: rgba(117, 122, 133, 0.45);}
.all-bg{background:$defaultColor;}
.online-bg{background: #0CCD70;}
.offline-bg{background: #FF0000;}
.stop-bg{background: #FF8A00;}
.ldle-bg{background: #8CB3FF;}
.signal-lost-bg{background: #91AAB9;}
.darkorange-txt{background: #FF8A00;}
.bg-green-box{background:#20B86F;}

.light-blue-btn{background: #D5EEFC;border-color: #D5EEFC;color: #5C9CBF;}

.err-top-72{top:72px;}
.pwd-eye-btn{
    input{padding-right: 36px !important;}
    button{right: 1px;top: 0;line-height: 30px;padding-top: 8px;}
}

.breadcrumb-bins {
  .breadcrumb li a,.breadcrumb li{font-size:12px; color:#000;}
  .breadcrumb-item+.breadcrumb-item{color:#CACACA}
  .breadcrumb-item+.breadcrumb-item::before{content:"\e932";font-family: "primeicons";speak: none;font-style: normal;font-weight: normal;font-feature-settings: normal;font-variant: normal;line-height: 1;display: inline-block;padding-top: 3px;color:#CACACA;}
}
.title-fiters {font-size: 14px;line-height: 16px;color: #215573;}
.clear-btn{font-size: 10px;line-height: 12px;color: $defaultColor;}
.filters-blocks{padding: 12px;
               .filters-options-blocks{
                                       .right-fillter-bk{padding-left:30px;
                                           &:before{content:"";width:2px;height:100%;position:absolute;left:0;top:0;background:#DDE5EA;}
                                       }
               }
}
.filter-btn{font-size: 10px;line-height: 12px;color: $defaultColor;}
.font-styles-12{font-size: 12px;line-height: 14px;color: #000000;}
.vehicle-status-blocks{
    ::placeholder {
      color: #A4CEE6 !important;
      opacity: 1; /* Firefox */
    }
        .filters-bg{background:#FBFDFF;border:#c9e3f2 solid 1px;border-radius: 2px;color: $colorTxt;font-size: 12px;width: 100%;text-align: left;height:32px;box-sizing:border-box;}
        input::placeholder,input:-ms-input-placeholder,input::-ms-input-placeholder {color: $colorTxt;}
        .icon-search{right: 10px;top: 6px;}
        input.filters-bg{padding-right: 30px;}
        .navlist-tabs .signal-bk{width: 85px;}
        .tab-names::-webkit-scrollbar { width: 0;} 
        .tab-cont-dteails{height: calc(100vh - 63px - 32px - 90px);overflow: auto;}
        .tab-vehicle-details-blocks{max-width:380px;padding: 20px 20px 0;height:100vh;box-shadow: 0px 5px 10px 0px rgb(152 171 182 / 29%);}
}
.alertBoxs{top: 30px;right: 30px;}
.progress-infos{position: absolute;width: calc(100% + 0px);left: 0px;bottom: 0px;border-bottom-left-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.progress-bar{animation-name: progressBar;animation-duration: 3s;}
@keyframes progressBar {
  0% {width:0%;}
  100% { width:100%;}
}
.toggle_switch {
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.5s;
  }

  input:checked + label {
    background: #19A3F1;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 130px;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #68747b;
  margin-left: 0.5rem;
  
}
.pi {
  font-size: 0.65rem;
}

.p-button {
  color: #ffffff;
  background: #6366F1;
  border: 1px solid #6366F1;
  padding: 0.75rem 1.25rem;
  padding: 0.5rem!important;
  font-size: 12px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-splitbutton .p-menu {
  min-width: 100%;
  font-size: 12px;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 12px;
}
.p-submenu-list {
  z-index: 100 !important;
}

.p-card .p-card-content {
  padding: 0;
}

.data-badge.status-active {
  background: #C8E6C9;
  color: #256029;
}
.data-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}
.data-badge.status-inactive {
  background: #FFCDD2;
  color: #c63737;
}
.custom-pad-bot {
  padding-bottom: 10px;
}

.p-menubar .p-submenu-list {
  max-height: 300px;
  overflow-y: auto;
}

.scrollable-table {
  height: 150px;
  overflow: auto;
}

.outside-border {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}

@media screen and (max-width: 600px) {
    #formSubmit .p-dialog {
      width: 100% !important;
      position: fixed;
      bottom: 0;
    }

    .scrollable-table {
      height: 300px;
      overflow: auto;
    }
}

.fillet-boxs-css .p-dropdown {
  padding: 3px !important;
}

:host ::ng-deep .tabview-custom {
  i, span { vertical-align: middle; } span { margin: 0 .5rem; }
}
:host ::ng-deep .p-button {
  margin-right: .25rem;
}
:host ::ng-deep .p-tabview p {
  line-height: 1.5; margin: 0;
}

a:hover {
  text-decoration: none !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.75rem;
}

p-accordion div {
  margin-bottom: 0 !important;
}

.custom-card .p-component, .p-component {
  margin-bottom: 10px;
}

.p-tabview .p-tabview-panels {
  padding: 0px;
}

.custom-head-table .ptable-css .p-datatable .p-datatable-header {
  padding: 10px 0px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.fillet-boxs-css .p-multiselect {
  padding: 3px !important;
}

.p-dropdown-label {
  font-size: 12px;
  padding: 10px;
  color: #19A3F1 !important
}

.p-multiselect-label {
  font-size: 12px;
  padding: 10px;
  color: #19A3F1 !important
}

.fillet-boxs-css .p-dropdown .p-dropdown-label.p-inputtext.p-placeholder {
  padding: 10px;
}

.p-component{
  font-size: 12px;
}
.disable-dropdown .p-dropdown{
  background-color: lightgray;
}

.disable-multiselect .p-multiselect{
  background-color: lightgray;
}

.custom-calendar .p-button-icon {
  color:#C9E3F2;
  font-size: 16px;
}

.customwidth .p-dropdown{
  width: 250px;
}

.custom-width .p-dropdown{ //for bhk form
  width: 44vw;
}

.custom-width .p-multiselect{ //for bhk form
  width: 44vw;
  min-width: 44vw;
}

.custom-preview p-image span div .p-image-toolbar
{ 
  z-index: 20000;
  background-color: black;
  opacity: 40%;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

.custom-dropdown-pending-task .p-dropdown {
  height: 40px !important;
}

.p-progressbar .p-progressbar-value {
  background-color: #007bff !important;
}

.custom-report .p-calendar {
  width: 100%;
  height: 49px;
}

.custom-module-log .p-calendar {
  width: 100%;
  height: 49px;
}

.custom-module-log .p-dropdown {
  width: 100%;
  height: 40px;
}

.custom-bhk-dropdown .p-dropdown {
  width: 100%;
  height: 40px;
}
